// Import Font Awesome CSS
import '@fortawesome/fontawesome-svg-core/styles.css';

// Create a custom Font Awesome library
import { config, library } from '@fortawesome/fontawesome-svg-core';

import {
  faAngleRight,
  faChevronLeft,
  faChevronRight,
  faCircleNotch,
  faDice,
  faDownload,
  faDungeon,
  faExternalLinkAlt,
  faEyeSlash,
  faFile,
  faFileUpload,
  faGlobe,
  faHourglassHalf,
  faLightbulb,
  faPaste,
  faPen,
  faQrcode,
  faQuestionCircle,
  faSignal,
  faSignOutAlt,
  faTasks,
  faTrash,
  faUsers,
  faWarehouse,
  faWindowRestore,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faAngleRight,
  faChevronLeft,
  faChevronRight,
  faCircleNotch,
  faDice,
  faDownload,
  faDungeon,
  faExternalLinkAlt,
  faEyeSlash,
  faFile,
  faFileUpload,
  faGlobe,
  faHourglassHalf,
  faLightbulb,
  faPaste,
  faPen,
  faQrcode,
  faQuestionCircle,
  faSignal,
  faSignOutAlt,
  faTasks,
  faTrash,
  faUsers,
  faWarehouse,
  faWindowRestore,
);

// Prevent inline <style> tag
// https://fontawesome.com/how-to-use/on-the-web/other-topics/security#policy
config.autoAddCss = false;
