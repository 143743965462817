const filterPartners = (partners = [], filters = {}) =>
  partners.filter((item) => {
    const {
      credentialIds = [],
      marketplaces = [],
      names,
      pods = [],
      inactive,
    } = item;

    const filterMatches = names.find((x) =>
      x.toLowerCase().includes(filters.name),
    );

    // If filtering by marketplace, check if this item matches the chosen filter
    const marketplaceMatches = !filters.marketplace
      ? true
      : Array.isArray(marketplaces) &&
        marketplaces.includes(filters.marketplace);

    // If filtering by credentialId, check if this item matches the chosen filter
    const credentialsMatches = !filters.credential
      ? true
      : credentialIds.includes(filters.credential);

    // If filtering by pods, check if this item matches the chosen filter
    const podMatches = !filters.pod ? true : pods.includes(filters.pod);

    let statusMatches;
    switch (filters.status) {
      case 'active':
        if (typeof inactive !== 'boolean' || !inactive) {
          statusMatches = true;
        } else {
          statusMatches = false;
        }
        break;
      case 'inactive':
        if (typeof inactive === 'boolean' && inactive) {
          statusMatches = true;
        } else {
          statusMatches = false;
        }
        break;
      default:
        statusMatches = true;
    }

    return (
      filterMatches &&
      marketplaceMatches &&
      credentialsMatches &&
      podMatches &&
      statusMatches
    );
  });

const filterUsers = (users = [], filters = {}) =>
  users.filter((item) => {
    const { name, email, partners } = item;
    const partnerIds = Object.keys(partners) || [];

    const nameMatches = name.toLowerCase().includes(filters.name);
    const emailMatches = email.toLowerCase().includes(filters.name);

    const pods = Array.from(
      new Set((item.pods || []).map((podId) => podId.split('|')[0])),
    );
    const podsMatches = !filters.pod || pods.includes(filters.pod);
    const rolesMatches =
      !filters.role || (item.roles || []).includes(filters.role);

    // If filtering by partner, check if this item matches the chosen filter
    const partnerMatches =
      !filters.partner || partnerIds.includes(filters.partner);

    return (
      (nameMatches || emailMatches) &&
      podsMatches &&
      rolesMatches &&
      partnerMatches
    );
  });

export { filterPartners, filterUsers };
