import React, { createContext, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import ApiContext from './Api';

const { useApi } = ApiContext;

const RolesContext = createContext();

const initialState = {
  isLoading: true,
  roles: [],
};
const reducer = (state, action) => {
  let returnValue;
  switch (action.type) {
    case 'getting roles': {
      returnValue = {
        ...state,
        isLoading: true,
      };
      break;
    }
    case 'set roles': {
      returnValue = {
        ...state,
        isLoading: false,
        roles: action.roles,
      };
      break;
    }
    case 'load permissions': {
      returnValue = {
        ...state,
        isLoading: false,
        roles: state.roles.map((role) => ({
          ...role,
          ...(role.id === action.role.id ? action.role : undefined),
        })),
      };
      break;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
  return returnValue;
};

function RolesProvider({ children }) {
  const { request } = useApi();
  const [state, dispatch] = useReducer(reducer, initialState);

  // Load roles from the API on provider mount
  const getRoles = async (params) => {
    const { ConsistentRead } = params || {};
    dispatch({
      type: 'getting roles',
    });
    return request(
      `/roles${ConsistentRead ? '?ConsistentRead=true' : ''}`,
      undefined,
      true,
    ).then((data) =>
      dispatch({
        type: 'set roles',
        roles: data,
      }),
    );
  };
  useEffect(getRoles, []);

  // Get permissions from the API for a single role
  const getPermissions = async (roleId) => {
    const role = state.roles.find(({ id }) => id === roleId);
    return role && role.permissions
      ? role.permissions
      : request(`/roles/${roleId}`).then((data) => {
          dispatch({
            type: 'load permissions',
            role: data,
          });
          return data.permissions;
        });
  };

  const rebuildPartnerList = async ({ id }) => {
    await request('/roles', {
      method: 'POST',
      body: JSON.stringify({
        ...(id ? { id } : undefined),
        clearRoleCache: true,
      }),
    });
    return getRoles({ ConsistentRead: true });
  };

  const refreshPermissions = async ({ partnerId, partnerCredentialIds }) => {
    await request('/roles', {
      method: 'POST',
      body: JSON.stringify({
        partnerId,
        partnerCredentialIds,
        refreshPermissions: true,
      }),
    });
    return getRoles({ ConsistentRead: true });
  };

  const addRole = async ({ id, name, permissions, oktaGroup }) => {
    await request('/roles', {
      method: 'POST',
      body: JSON.stringify({
        ...(id ? { id } : undefined),
        name,
        permissions,
        oktaGroup,
      }),
    });
    return getRoles({ ConsistentRead: true });
  };

  // Prepare state object passed to consumers
  const value = {
    ...state,
    addRole,
    rebuildPartnerList,
    refreshPermissions,
    getPermissions,
  };

  return (
    <RolesContext.Provider value={value}>{children}</RolesContext.Provider>
  );
}
RolesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useRoles = () => {
  const context = useContext(RolesContext);
  if (context === undefined) {
    throw new Error('useRoles must be used within a RolesProvider');
  }
  return context;
};

export default {
  RolesProvider,
  useRoles,
};
